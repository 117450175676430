import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import('../components/Login.vue')
const Updated = () => import('../components/Updated.vue')
const Home = () => import('../components/Home.vue')
const Overview = () => import('../components/Belen/Overview.vue')
const Attract = () => import('../components/Belen/Attract.vue')
const Industry = () => import('../components/Belen/Industry.vue')
const Investment = () => import('../components/Fusion/Investment.vue')
const OverviewItem = () => import('../components/Overview/OverviewItem.vue')
const EmployeeList = () => import('../components/Employee/EmployeeList.vue')
const Logincode = () => import('../components/LoginCode/Logincode.vue')
const Getcode = () => import('../components/Getcode.vue')
const innovateOverview = () => import('../components/Innovate/innovateOverview.vue')
const innovateInvestment = () => import('../components/Innovate/innovateInvestment.vue')
const zgcCompany = () => import('../components/Innovate/zgc_company.vue')
const parkEnterprise = () => import('../components/Park/parkEnterprise.vue')
const groupManager = () => import('../components/group/groupManager.vue')
const dahang = () => import('../components/dahang/dahang.vue')
const haixing = () => import('../components/haixing/haixing.vue')
const otherAssets = () => import('../components/otherAssets/otherAssets.vue')

const xinyaOverview = () => import('../components/Xinya/Overview.vue')
const bjwxzzcOverview = () => import('../components/Bjwxzzc/Overview.vue')
const shihaoyuanOverview = () => import('../components/Shihaoyuan/Overview.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // redirect: '/home'
    redirect: '/logincode'
  },
  {
    path: '/getCode',
    component: Getcode
  },
  {
    path: '/logincode',
    component: Logincode
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/updated',
    component: Updated
  },
  {
    path: '/home',
    component: Home,
    // redirect: '/overview',
    children: [
      { path: '/overview', component: Overview }, // 园区总览
      { path: '/attract', component: Attract }, // 园区招商
      { path: '/industry', component: Industry }, // 园区产业
      { path: '/investment', component: Investment }, // 园区招商
      { path: '/overviewItem', component: OverviewItem }, // 项目总览
      { path: '/employeeList', component: EmployeeList }, // 员工列表
      { path: '/innovateOverview', component: innovateOverview }, // 中关村国际创新大厦 园区总览
      { path: '/innovateInvestment', component: innovateInvestment }, // 中关村国际创新大厦 园区招商
      { path: '/zgc_company', component: zgcCompany },
      { path: '/park', component: parkEnterprise }, // 企业列表

      { path: '/groupManager', component: groupManager }, // 集团管理
      { path: '/dahang', component: dahang }, // 大行基业
      { path: '/haixing', component: haixing }, // 海星
      { path: '/otherAssets', component: otherAssets }, // 其他资产

      { path: '/xinyaOverview', component: xinyaOverview }, // 馨雅大厦
      { path: '/bjwxzzcOverview', component: bjwxzzcOverview }, // 北京卫星制造厂
      { path: '/shihaoyuanOverview', component: shihaoyuanOverview } // 十号院

    ]
  }
]

const router = new VueRouter({
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //  next() 放行     next('/login) 强制跳转
  const token = window.sessionStorage.getItem('token')
  if (to.path === '/logincode' || to.path === '/login' || to.path === '/getcode') return next()
  if (!token) {
    return next('/logincode')
  } else {
    next()
  }
})

export default router
